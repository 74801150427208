/**
 * Contains the information about a white label app that is used in the balance component.
 */
export interface WhiteLabelBrandInfo {
  name: string;
  id: number;
  partnerText: string;
  appLink: string;
}

const BakedWhiteLabel: WhiteLabelBrandInfo = {
  name: 'Baked Potato',
  id: 3,
  partnerText: 'A Baked Potato é parceira da Onyo. Quer resgatar na Baked Potato e em diversas outras marcas?',
  appLink: 'https://aysv2.app.goo.gl/bakedpotato'
};

/**
 * Contains a map of all white label apps available.
 */
export const WhiteLabelBrands = new Map<number, WhiteLabelBrandInfo>([
]);
