import { Injectable } from '@angular/core';
import * as jwtDecode from 'jwt-decode';
import { TokenPayload } from '@app/model/token-payload.model';

/**
 * Wrapper for the JWT Decode
 */
@Injectable({
  providedIn: 'root'
})
export class JWTService {

  constructor() { }

  decodePayload(token: string): TokenPayload {
    const decodedValue: any = jwtDecode(token);
    return decodedValue.identity as TokenPayload;
  }
}
