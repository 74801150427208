<div class="error-text mat-typography">
  <p class="text">
    {{ errorMsg }}
  </p>
  <p class="text">
    Por favor tente novamente ou entre em contato com o nosso suporte.
  </p>

  <mat-list>
    <mat-list-item fxLayout fxLayoutAlign="center center">
      <mat-icon mat-list-icon>phone</mat-icon>
      3003-0548
    </mat-list-item>
    <mat-list-item fxLayout fxLayoutAlign="center center">
      <mat-icon mat-list-icon>mail_outline</mat-icon>
      <a href="ajuda@onyo.com">ajuda@onyo.com</a>
    </mat-list-item>
  </mat-list>
</div>