<div class="onyo-footer container" fxLayout="column" fxLayoutAlign="center center">
  <div class="footer-icon">
    <img src="assets/images/logo-sm.png" />
  </div>

  <h2 class="footer-icon-text mat-h2">
   O que é a Onyo?
  </h2>

  <div class="footer-text mat-body-1">
    A Onyo é uma plataforma de pedidos e fidelidade em praças de alimentação, que atua em parceria com diversas marcas de restaurantes.
  </div>
  <div class="footer-badges" fxLayout="row" fxLayoutAlign="space-evenly center">
    <div *ngIf="showGooglePlayBagde" class="google-play-badge">
      <a href="https://play.google.com/store/apps/details?id=com.onyo">
        <img src="assets/images/google-play-badge.png" class="store-badge" />
      </a>
    </div>
    <div *ngIf="showAppleStoreBadge" class="apple-store-badge">
      <a href="https://itunes.apple.com/br/app/onyo/id1132629081">
        <img src="assets/images/apple-store-badge.svg" class="store-badge" />
      </a>
    </div>
  </div>
</div>