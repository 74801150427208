export var ProductType;
(function (ProductType) {
    ProductType["Simple"] = "SIMPLE";
    ProductType["Choosable"] = "CHOOSABLE";
    ProductType["MenuItem"] = "MENU_ITEM";
})(ProductType || (ProductType = {}));
export var OnlineStatus;
(function (OnlineStatus) {
    OnlineStatus["Online"] = "ONLINE";
    OnlineStatus["Offline"] = "OFFLINE";
    OnlineStatus["Closed"] = "CLOSED";
})(OnlineStatus || (OnlineStatus = {}));
export var OrderFieldType;
(function (OrderFieldType) {
    OrderFieldType["Text"] = "TEXT";
    OrderFieldType["Numeric"] = "NUMERIC";
    OrderFieldType["Telephone"] = "TELEPHONE";
    OrderFieldType["Cpf"] = "CPF";
    OrderFieldType["OptionList"] = "OPTION_LIST";
})(OrderFieldType || (OrderFieldType = {}));
export var CompanyDemandStatus;
(function (CompanyDemandStatus) {
    CompanyDemandStatus["Unknown"] = "UNKNOWN";
    CompanyDemandStatus["Normal"] = "NORMAL";
    CompanyDemandStatus["High"] = "HIGH";
    CompanyDemandStatus["Blocked"] = "BLOCKED";
})(CompanyDemandStatus || (CompanyDemandStatus = {}));
export var AuthUserType;
(function (AuthUserType) {
    AuthUserType["Crm"] = "CRM";
})(AuthUserType || (AuthUserType = {}));
import * as Apollo from "apollo-angular";
import gql from "graphql-tag";
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
// ====================================================
// Apollo Services
// ====================================================
export class CustomerBalanceGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = gql `
    query CustomerBalance {
      me {
        ... on Customer {
          balance {
            edges {
              node {
                points
                brand {
                  name
                  numericalId
                  images {
                    context
                    url
                  }
                  brandTheme {
                    edges {
                      node {
                        brandThemeColor {
                          key
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on TemporaryUser {
          balance {
            edges {
              node {
                points
                brand {
                  name
                  numericalId
                  images {
                    context
                    url
                  }
                  brandTheme {
                    edges {
                      node {
                        brandThemeColor {
                          key
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
    }
}
CustomerBalanceGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomerBalanceGQL_Factory() { return new CustomerBalanceGQL(i0.ɵɵinject(i1.Apollo)); }, token: CustomerBalanceGQL, providedIn: "root" });
export class CustomerBrandBalanceGQL extends Apollo.Query {
    constructor() {
        super(...arguments);
        this.document = gql `
    query CustomerBrandBalance($brandId: Int!) {
      me {
        ... on Customer {
          balance(brandId: $brandId) {
            edges {
              node {
                points
                brand {
                  name
                  numericalId
                  images {
                    context
                    url
                  }
                  brandTheme {
                    edges {
                      node {
                        brandThemeColor {
                          key
                          value
                        }
                        brandThemeMessage {
                          key
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on TemporaryUser {
          balance(brandId: $brandId) {
            edges {
              node {
                points
                brand {
                  name
                  numericalId
                  images {
                    context
                    url
                  }
                  brandTheme {
                    edges {
                      node {
                        brandThemeColor {
                          key
                          value
                        }
                        brandThemeMessage {
                          key
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
    }
}
CustomerBrandBalanceGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomerBrandBalanceGQL_Factory() { return new CustomerBrandBalanceGQL(i0.ɵɵinject(i1.Apollo)); }, token: CustomerBrandBalanceGQL, providedIn: "root" });
export class SignUpUserGQL extends Apollo.Mutation {
    constructor() {
        super(...arguments);
        this.document = gql `
    mutation SignUpUser(
      $email: String!
      $name: String!
      $password: String!
      $telephone: String!
    ) {
      customerEngagementSignUp(
        email: $email
        name: $name
        password: $password
        telephone: $telephone
      ) {
        success
        error
      }
    }
  `;
    }
}
SignUpUserGQL.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SignUpUserGQL_Factory() { return new SignUpUserGQL(i0.ɵɵinject(i1.Apollo)); }, token: SignUpUserGQL, providedIn: "root" });
const result = {
    __schema: {
        types: [
            {
                kind: "INTERFACE",
                name: "Node",
                possibleTypes: [
                    {
                        name: "Company"
                    },
                    {
                        name: "Brand"
                    },
                    {
                        name: "Category"
                    },
                    {
                        name: "Product"
                    },
                    {
                        name: "ProductCompany"
                    },
                    {
                        name: "BrandTheme"
                    },
                    {
                        name: "BrandThemeColor"
                    },
                    {
                        name: "BrandThemeMessage"
                    },
                    {
                        name: "Address"
                    },
                    {
                        name: "Parameter"
                    },
                    {
                        name: "PaymentParameter"
                    },
                    {
                        name: "CardBrand"
                    },
                    {
                        name: "OrderTypeParameter"
                    },
                    {
                        name: "OrderFieldParameter"
                    },
                    {
                        name: "OrderField"
                    },
                    {
                        name: "CompanyGroup"
                    },
                    {
                        name: "AccountsUser"
                    },
                    {
                        name: "User"
                    },
                    {
                        name: "Customer"
                    },
                    {
                        name: "Reward"
                    },
                    {
                        name: "TemporaryUser"
                    }
                ]
            },
            {
                kind: "UNION",
                name: "Person",
                possibleTypes: [
                    {
                        name: "Customer"
                    },
                    {
                        name: "TemporaryUser"
                    }
                ]
            }
        ]
    }
};
export default result;
