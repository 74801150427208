<div class="white-label-balance mat-typography" fxLayout="column" fxLayoutAlign="space-evenly center">
  <div>
    <img [src]="brand.logoUrl" class="brand-logo" />
  </div>

  <div>
    <app-onyo-logo></app-onyo-logo>
  </div>

  <div fxLayout="row" fxLayoytAlign="center center">
    <div class="loyalty-points" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="loyaltyPointsStyle">
      <div class="loyalty-text-number">
        {{ brand.loyaltyPoints }}
      </div>
      <div class="loyalty-text-points">
        {{ loyaltyPointsText }}
      </div>
    </div>
  </div>

  <div class="mat-body balance-text">
    Você já pode resgatar <strong>{{ brand.loyaltyPoints }} {{ loyaltyPointsText }} através do App {{ brand.name }}:</strong>
  </div>
  <div>
    <a mat-flat-button class="onyo-app-btn" color="primary" [href]="whiteLabelInfo.appLink" [ngStyle]="buttonStyle">
      <h2 class="btn-text">Baixar o App {{ whiteLabelInfo.name }}!</h2>
    </a>
  </div>

  <div *ngIf="hasAdditionalPoints" fxLayout="column" fxLayoutAlign="center center">
    <div class="mat-body balance-text">
      {{ whiteLabelInfo.partnerText }}
    </div>
    <div class="mat-body balance-text">
      <a class="onyo-app-link" href="https://app.onyo.com">Experimente o App Onyo</a>, onde:
    </div>
  </div>
  <div *ngIf="!hasAdditionalPoints" class="mat-body balance-text">
    <div class="mat-body balance-text">
      {{ whiteLabelInfo.partnerText }}
    </div>
    <div class="mat-body balance-text">
      <a class="onyo-app-link" href="https://app.onyo.com">Experimente o App Onyo.</a>
    </div>
  </div>
</div>