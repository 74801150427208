/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./onyo-logo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "./onyo-logo.component";
var styles_OnyoLogoComponent = [i0.styles];
var RenderType_OnyoLogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OnyoLogoComponent, data: {} });
export { RenderType_OnyoLogoComponent as RenderType_OnyoLogoComponent };
export function View_OnyoLogoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "onyo-logo"], ["fxLayout", ""], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["href", "https://app.onyo.com"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "logo"], ["src", "assets/images/logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "powered-by-text mat-small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Powered By:"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); }, null); }
export function View_OnyoLogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-onyo-logo", [], null, null, null, View_OnyoLogoComponent_0, RenderType_OnyoLogoComponent)), i1.ɵdid(1, 114688, null, 0, i4.OnyoLogoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OnyoLogoComponentNgFactory = i1.ɵccf("app-onyo-logo", i4.OnyoLogoComponent, View_OnyoLogoComponent_Host_0, {}, {}, []);
export { OnyoLogoComponentNgFactory as OnyoLogoComponentNgFactory };
