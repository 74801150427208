import 'hammerjs';

import { Component, OnInit } from '@angular/core';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { Apollo } from 'apollo-angular';
import { environment } from '@env/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private apollo: Apollo, private gtag: Angulartics2GoogleGlobalSiteTag) { }

  ngOnInit() {
    if (environment.production) {
      this.gtag.startTracking();
    }

    // This is here to avoid an error with the Graphql dev tools.
    // https://github.com/apollographql/apollo-client-devtools/issues/147
    this.apollo.getClient().initQueryManager();
  }
}
