/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../error-text/error-text.component.ngfactory";
import * as i6 from "../error-text/error-text.component";
import * as i7 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./error-dialog.component";
var styles_ErrorDialogComponent = [i0.styles];
var RenderType_ErrorDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorDialogComponent, data: {} });
export { RenderType_ErrorDialogComponent as RenderType_ErrorDialogComponent };
export function View_ErrorDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "error-dialog mat-typography"], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h2", [["class", "dialog-title mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(4, 81920, null, 0, i4.MatDialogTitle, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Ops, deu alguma coisa errada..."])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-error-text", [], null, null, null, i5.View_ErrorTextComponent_0, i5.RenderType_ErrorTextComponent)), i1.ɵdid(9, 114688, null, 0, i6.ErrorTextComponent, [], { errorMsg: [0, "errorMsg"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 5, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["color", "primary"], ["mat-dialog-close", ""], ["mat-flat-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).dialogRef.close(i1.ɵnov(_v, 14).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(14, 606208, null, 0, i4.MatDialogClose, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Fechar"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); _ck(_v, 4, 0); var currVal_3 = _co.data.errorMsg; _ck(_v, 9, 0, currVal_3); var currVal_7 = "primary"; _ck(_v, 13, 0, currVal_7); var currVal_8 = ""; _ck(_v, 14, 0, currVal_8); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 4).id; _ck(_v, 3, 0, currVal_2); var currVal_4 = (i1.ɵnov(_v, 13).disabled || null); var currVal_5 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); var currVal_6 = (i1.ɵnov(_v, 14).ariaLabel || null); _ck(_v, 12, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_ErrorDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-dialog", [], null, null, null, View_ErrorDialogComponent_0, RenderType_ErrorDialogComponent)), i1.ɵdid(1, 114688, null, 0, i11.ErrorDialogComponent, [i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorDialogComponentNgFactory = i1.ɵccf("app-error-dialog", i11.ErrorDialogComponent, View_ErrorDialogComponent_Host_0, {}, {}, []);
export { ErrorDialogComponentNgFactory as ErrorDialogComponentNgFactory };
