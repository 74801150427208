import { Angulartics2 } from 'angulartics2';
import { WhiteLabelBrands } from '@app/model/white-label-brand-info.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ErrorDialogComponent } from '@app/components/error-dialog/error-dialog.component';
import { CustomerBrandBalance, CustomerBrandBalanceGQL, SignUpUser, SignUpUserGQL } from '@app/graphql/generated-graphql';
import { BrandSignUp } from '@app/model/brand-sign-up.model';
import { BrandParserService } from '@app/services/brand-parser/brand-parser.service';
import { JWTService } from '@app/services/jwt/jwt.service';
import * as Sentry from '@sentry/browser';
import { ApolloQueryResult } from 'apollo-client';
import { Subject, throwError } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {

  onDestroy$: Subject<void>;
  loading: boolean;
  error: boolean;
  token: string;
  signUpForm: FormGroup;
  brandData: BrandSignUp;
  buttonStyle: any;
  tokenTelephone: string;
  loyaltyPointsText: string;
  dialogMaxWidth = 0.8 * 500;

  constructor(
    private jwt: JWTService,
    private route: ActivatedRoute,
    private brandInfo: CustomerBrandBalanceGQL,
    private signUpUser: SignUpUserGQL,
    private brandParser: BrandParserService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private analytics: Angulartics2
  ) { }

  ngOnInit() {
    this.onDestroy$ = new Subject<void>();
    this.initForm();

    this.loading = true;
    this.error = false;
    this.loadData();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  initForm() {
    this.signUpForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirmation: ['', Validators.required]
    }, {
      validators: this.passwordValidator
    });
  }

  private loadData() {
    // Make sure we subscribe to this observable once the view is propertly loaded to avoid expression changed after it was checked error.
    this.route.queryParamMap
      .pipe(
        takeUntil(this.onDestroy$),
        filter(params => params.has('t')),
        switchMap((params: ParamMap) => {
          this.token = params.get('t');
          const payload = this.jwt.decodePayload(this.token);
          this.tokenTelephone = payload.telephone;

          if (payload && payload.brand_id) {
            return this.brandInfo.watch(
              { brandId: payload.brand_id },
              { context: { headers: { Authorization: `Bearer ${this.token}` } } }
            ).valueChanges;
          }

          console.warn('Token brand_id is undefined', payload);
          return throwError(new Error('Error fetching brand_id from token'));
        })
      )
      .subscribe(
        (queryResult: ApolloQueryResult<CustomerBrandBalance.Query>) => {
          if (queryResult.data.me.__typename === 'Customer') {
            this.analytics.eventTrack.next({ action: 'user-is-customer' });
            this.router.navigate(['/balance'], { queryParams: { t: this.token } });
          }
          else {
            this.brandData = this.brandParser.parseBrandSignUpInfo(queryResult.data.me.balance.edges[0].node);

            // Create Styles for white label app.
            if (WhiteLabelBrands.has(this.brandData.id)) {
              this.buttonStyle = { 'background-color': this.brandData.themeColor };
            }

            if (this.brandData.loyaltyPoints > 1) {
              this.loyaltyPointsText = 'pontos';
            }
            else {
              this.loyaltyPointsText = 'ponto';
            }
          }

          this.loading = false;
        },
        (error) => {
          const errorMsg = 'Could not fetch brand info';
          console.error(errorMsg, error);
          Sentry.addBreadcrumb({ message: errorMsg, level: Sentry.Severity.Error, data: error });
          Sentry.captureException(error);
          this.analytics.eventTrack.next({ action: 'error-fetching-brand-info', properties: { category: 'graphql' } });

          this.loading = false;
          this.error = true;
        }
      );
  }

  onSubmit() {
    if (this.signUpForm.invalid) {
      this.analytics.eventTrack.next({ action: 'invalid-form-submit', properties: { category: 'sign-up-form' } });
      return;
    }

    this.analytics.eventTrack.next({ action: 'sign-up-form-submit', properties: { category: 'sign-up-form' } });
    this.loading = true;
    this.signUpUser.mutate({
      email: this.signUpForm.get('email').value,
      name: this.signUpForm.get('name').value,
      password: this.signUpForm.get('password').value,
      telephone: this.tokenTelephone
    }, {
      context: { headers: { Authorization: `Bearer ${this.token}` } }
    }).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(
      (result: { data: SignUpUser.Mutation }) => {
        if (result.data.customerEngagementSignUp.success) {
          this.analytics.eventTrack.next({ action: 'sign-up', properties: { category: 'sign-up-form' } });
          this.router.navigate(['/balance'], { queryParams: { t: this.token } });
        }
        else {
          let errorMsg: string;
          const err: any = new Error(result.data.customerEngagementSignUp.error);
          err.formData = this.getFormData();
          console.error('Error on user sign in', err);
          Sentry.captureException(err);
          this.analytics.eventTrack.next({
            action: result.data.customerEngagementSignUp.error,
            properties: { category: 'sign-up-form' }
          });

          switch (result.data.customerEngagementSignUp.error) {
            case 'onyo.user.invalid-data':
              errorMsg = 'Ops, os dados que você forneceu são inválidos.';
              break;

            case 'onyo.user.email-already-exists':
            case 'onyo.user.email-duplicated':
              errorMsg = 'Ops, já existe um usuário com esse e-mail.';
              break;

            case 'onyo.user.phone-already.exists':
              errorMsg = 'Ops, esse usuário já está cadastrado.';
              break;

            case 'onyo.user.invalid-password':
              errorMsg = 'Ops, a senha que você digitou é inválida, ela tem que ter no mínimo 6 caracteres.';
              break;
              
            case 'onyo.user.email-undeliverable':
              errorMsg = 'Ops, este email é inválido.';
              break;

            default:
              errorMsg = 'Ops, ocorreu um erro no cadastro.';
              break;
          }

          this.dialog.open(
            ErrorDialogComponent,
            {
              maxWidth: this.dialogMaxWidth,
              data: { errorMsg }
            }
          );
        }

        this.loading = false;
      },
      (error) => {
        const errorMsg = 'Could not sign up user';
        console.error(errorMsg, error);

        const err: any = new Error(errorMsg);
        err.formData = this.getFormData();
        err.data = error;
        Sentry.captureException(error);
        this.analytics.eventTrack.next({
          action: 'sign-up-error',
          properties: { category: 'sign-up-form' }
        });

        this.dialog.open(
          ErrorDialogComponent,
          {
            maxWidth: this.dialogMaxWidth,
            data: { errorMsg: 'Ops, ocorreu um erro ao criar o seu usário.' }
          }
        );
        this.loading = false;
      }
    );
  }

  passwordValidator(form: FormGroup) {
    const condition = form.get('password').value !== form.get('passwordConfirmation').value;
    return condition ? { passwordsDoNotMatch: true } : null;
  }

  // Helper method to get the form controls
  get controls() {
    return this.signUpForm.controls;
  }

  private getFormData(): any {
    return {
      email: this.signUpForm.get('email').value,
      firstName: this.signUpForm.get('name').value,
      password: this.signUpForm.get('password').value,
    };
  }
}
