import { Brand } from '@app/model/brand.model';
import * as i0 from "@angular/core";
/**
 * This service parses the brand response that comes from the GraphQL
 */
export class BrandParserService {
    constructor() { }
    parseBalanceInfo(balance) {
        const model = new Brand();
        model.loyaltyPoints = balance.points;
        model.name = balance.brand.name;
        model.id = balance.brand.numericalId;
        for (const img of balance.brand.images) {
            if (img.context === 'onyo.ios.theme.navigation.logo') {
                model.logoUrl = img.url;
            }
            else if (img.context === 'brand-aggregator-company-list') {
                model.smallLogoUrl = img.url;
            }
        }
        for (const color of balance.brand.brandTheme.edges[0].node.brandThemeColor) {
            if (color.key === 'buttonsColor') {
                model.themeColor = color.value;
            }
        }
        return model;
    }
    /**
     * Parse a single brand.
     */
    parseBrandSignUpInfo(balance) {
        const brandModel = this.parseBalanceInfo(balance);
        let loyaltyDescription;
        for (const msg of balance.brand.brandTheme.edges[0].node.brandThemeMessage) {
            if (msg.key === 'onyo.mobile.theme.companyLoyaltyNew.firstPage.description') {
                loyaltyDescription = msg.value.split(';');
            }
        }
        return Object.assign({}, brandModel, { loyaltyDescription });
    }
}
BrandParserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrandParserService_Factory() { return new BrandParserService(); }, token: BrandParserService, providedIn: "root" });
