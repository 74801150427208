import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BalanceViewComponent } from '@app/components/balance-view/balance-view.component';
import { PageNotFoundComponent } from '@app/components/page-not-found/page-not-found.component';
import { SignUpComponent } from '@app/components/sign-up/sign-up.component';

const routes: Routes = [
  {
    path: 'sign-up',
    component: SignUpComponent
  },
  {
    path: 'balance',
    component: BalanceViewComponent
  },
  {
    path: '404',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
