import { Angulartics2 } from 'angulartics2';
import { WhiteLabelBrands } from '@app/model/white-label-brand-info.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CustomerBalance, CustomerBalanceGQL } from '@app/graphql/generated-graphql';
import { Brand } from '@app/model/brand.model';
import { TokenPayload } from '@app/model/token-payload.model';
import { BrandParserService } from '@app/services/brand-parser/brand-parser.service';
import { JWTService } from '@app/services/jwt/jwt.service';
import * as Sentry from '@sentry/browser';
import { ApolloQueryResult } from 'apollo-client';
import { Subject, throwError } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-balance-view',
  templateUrl: './balance-view.component.html',
  styleUrls: ['./balance-view.component.scss']
})
export class BalanceViewComponent implements OnInit {

  onDestroy$: Subject<void>;
  loading: boolean;
  isWhiteLabel: boolean;
  showError: boolean;
  brands: Brand[];
  mainBrand: Brand;
  hasAdditionalPoints: boolean;
  payload: TokenPayload;

  constructor(
    private customerBalance: CustomerBalanceGQL,
    private route: ActivatedRoute,
    private jwt: JWTService,
    private brandParser: BrandParserService,
    private analytics: Angulartics2
  ) { }

  ngOnInit() {
    this.brands = [];
    this.onDestroy$ = new Subject<void>();

    this.loadData();
  }

  private loadData() {
    this.loading = true;
    this.isWhiteLabel = false;

    this.route.queryParamMap
      .pipe(
        takeUntil(this.onDestroy$),
        filter(params => params.has('t')),
        switchMap((params: ParamMap) => {
          const token = params.get('t');
          this.payload = this.jwt.decodePayload(token);

          if (this.payload && this.payload.brand_id) {
            return this.customerBalance.watch({}, { context: { headers: { Authorization: `Bearer ${token}` } } }).valueChanges;
          }

          console.warn('Token brand_id is undefined', this.payload);
          return throwError(new Error('Error fetching brand_id from token'));
        })
      )
      .subscribe((result: ApolloQueryResult<CustomerBalance.Query>) => {
        for (const balance of result.data.me.balance.edges) {
          const brand = this.brandParser.parseBalanceInfo(balance.node);

          if (brand.id === this.payload.brand_id) {
            this.mainBrand = brand;
          }
          else if (brand.loyaltyPoints > 0) {
            this.brands.push(brand);
          }
        }

        this.isWhiteLabel = WhiteLabelBrands.has(this.mainBrand.id);

        this.hasAdditionalPoints = this.brands.length > 0;
        this.loading = false;
      },
      (error) => {
        const errorMsg = 'Could not fetch balance info';
        console.error(errorMsg, error);
        Sentry.addBreadcrumb({ message: errorMsg, level: Sentry.Severity.Error, data: error });
        Sentry.captureException(error);
        this.analytics.eventTrack.next({ action: 'error-fetching-balance', properties: { category: 'graphql' } });

        // tslint:disable-next-line:max-line-length
        this.showError = true;
        this.loading = false;
      }
    );
  }
}
