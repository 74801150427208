export class Brand {
  id: number;
  name: string;
  logoUrl: string;
  smallLogoUrl: string;
  themeColor: string;
  loyaltyPoints: number;

  constructor() {
    this.id = -1;
    this.logoUrl = '';
    this.smallLogoUrl = '';
    this.themeColor = '';
    this.loyaltyPoints = 0;
  }
}
