import { Component, Input, OnInit } from '@angular/core';
import { Brand } from '@app/model/brand.model';
import { WhiteLabelBrandInfo, WhiteLabelBrands } from '@app/model/white-label-brand-info.model';

@Component({
  selector: 'app-white-label-balance',
  templateUrl: './white-label-balance.component.html',
  styleUrls: ['./white-label-balance.component.scss']
})
export class WhiteLabelBalanceComponent implements OnInit {

  @Input() brand: Brand;
  @Input() hasAdditionalPoints: boolean;

  loyaltyPointsStyle: any;
  loyaltyPointsText: string;
  whiteLabelInfo: WhiteLabelBrandInfo;
  buttonStyle: any;

  constructor() { }

  ngOnInit() {
    if (this.brand.loyaltyPoints > 1) {
      this.loyaltyPointsText = 'pontos';
    }
    else {
      this.loyaltyPointsText = 'ponto';
    }

    this.loyaltyPointsStyle = {
      color: this.brand.themeColor,
      'border-color': this.brand.themeColor
    };

    this.whiteLabelInfo = WhiteLabelBrands.get(this.brand.id);
    this.buttonStyle = { 'background-color': this.brand.themeColor };
  }

}
