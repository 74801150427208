<div class="main-balance mat-typography" fxLayout="column" fxLayoutAlign="space-evenly center">
  <div>
    <img [src]="brand.logoUrl" class="brand-logo" />
  </div>

  <div>
    <app-onyo-logo></app-onyo-logo>
  </div>

  <div fxLayout="row" fxLayoytAlign="center center">
    <div class="loyalty-points" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="loyaltyPointsStyle">
      <div class="loyalty-text-number">
        {{ brand.loyaltyPoints }}
      </div>
      <div class="loyalty-text-points">
        {{ loyaltyPointsText }}
      </div>
    </div>
  </div>

  <div class="mat-body balance-text">
    Você já pode resgatar <strong>{{ brand.loyaltyPoints }} {{ loyaltyPointsText }} através do App Onyo:</strong>
  </div>
  <div>
    <a mat-flat-button class="onyo-app-btn" color="primary" href="https://app.onyo.com">
      <h2 class="btn-text">Baixar o App Onyo!</h2>
    </a>
  </div>

  <div *ngIf="hasAdditionalPoints" class="mat-body balance-text">
    Além da marca {{ brand.name }}, essas outras marcas estão esperando seu resgate no App Onyo:
  </div>
</div>