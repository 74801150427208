import { Brand } from '@app/model/brand.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-main-balance',
  templateUrl: './main-balance.component.html',
  styleUrls: ['./main-balance.component.scss']
})
export class MainBalanceComponent implements OnInit {

  loyaltyPointsStyle: any;
  loyaltyPointsText: string;
  @Input() brand: Brand;
  @Input() hasAdditionalPoints: boolean;

  constructor() { }

  ngOnInit() {
    if (this.brand.loyaltyPoints > 1) {
      this.loyaltyPointsText = 'pontos';
    }
    else {
      this.loyaltyPointsText = 'ponto';
    }

    this.loyaltyPointsStyle = {
      color: this.brand.themeColor,
      'border-color': this.brand.themeColor
    };
  }

}
