import { CustomerBalance, CustomerBrandBalance } from '@app/graphql/generated-graphql';
import { Injectable } from '@angular/core';
import { BrandSignUp } from '@app/model/brand-sign-up.model';
import { Brand } from '@app/model/brand.model';

/**
 * This service parses the brand response that comes from the GraphQL
 */
@Injectable({
  providedIn: 'root'
})
export class BrandParserService {

  constructor() { }

  parseBalanceInfo(balance: CustomerBalance.Node): Brand {
    const model = new Brand();

    model.loyaltyPoints = balance.points;
    model.name = balance.brand.name;
    model.id = balance.brand.numericalId;

    for (const img of balance.brand.images) {
      if (img.context === 'onyo.ios.theme.navigation.logo') {
        model.logoUrl = img.url;
      }
      else if (img.context === 'brand-aggregator-company-list') {
        model.smallLogoUrl = img.url;
      }
    }

    for (const color of balance.brand.brandTheme.edges[0].node.brandThemeColor) {
      if (color.key === 'buttonsColor') {
        model.themeColor = color.value;
      }
    }

    return model;
  }

  /**
   * Parse a single brand.
   */
  parseBrandSignUpInfo(balance: CustomerBrandBalance.Node): BrandSignUp {
    const brandModel = this.parseBalanceInfo(balance);

    let loyaltyDescription: string[];
    for (const msg of balance.brand.brandTheme.edges[0].node.brandThemeMessage) {
      if (msg.key === 'onyo.mobile.theme.companyLoyaltyNew.firstPage.description') {
        loyaltyDescription = msg.value.split(';');
      }
    }

    return { ...brandModel, loyaltyDescription };
  }
}
