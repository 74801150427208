<div class="balance-view" fxLayout="column" fxLayoutAlign="center center">
  <div *ngIf="!loading && !showError" class="balance">
    <div *ngIf="!isWhiteLabel" class="onyo-app">
      <app-main-balance [brand]="mainBrand" [hasAdditionalPoints]="hasAdditionalPoints"></app-main-balance>
    </div>
    <div *ngIf="isWhiteLabel" class="white-label-app">
      <app-white-label-balance [brand]="mainBrand" [hasAdditionalPoints]="hasAdditionalPoints"></app-white-label-balance>
    </div>

    <div *ngFor="let brand of brands">
      <app-balance [brand]="brand"></app-balance>
    </div>
  </div>
  <div *ngIf="loading" class="loading-spinner">
    <mat-spinner strokeWidth="6"></mat-spinner>
  </div>
  <div class="error" *ngIf="showError">
    <app-error-text errorMsg="Ops, ocorreu um erro ao carregar os seus dados."></app-error-text>
  </div>
</div>
