<div class="sign-up" fxLayout="column" fxLayoutAlign="center center">

  <div *ngIf="!loading && !error" class="content">
    <div fxLayout fxLayoutAlign="center center">
      <img [src]="brandData.logoUrl" />
    </div>

    <div>
      <app-onyo-logo></app-onyo-logo>
    </div>

    <div class="brand-text mat-body-1">
      <strong>Você tem {{ brandData.loyaltyPoints }} {{ loyaltyPointsText }} na marca {{ brandData.name }}.</strong>
      Cadastre-se e baixe o app para continuar pontuando e resgatar produtos!
    </div>

    <div fxLayout fxLayoutAlign="center center">
      <img src="assets/images/standard-loyalty-icon.svg" />
    </div>

    <div class="loyalty-rules" fxLayout="column" fxLayoutAlign="center center">
      <div *ngFor="let desc of brandData.loyaltyDescription" class="mat-caption">
          {{ desc }}
      </div>
    </div>

    <form [formGroup]="signUpForm" class="sign-up-form" fxLayout="column" (ngSubmit)="onSubmit()">
      <mat-form-field>
        <mat-label>Nome completo*</mat-label>
        <input matInput formControlName="name" type="text">
        <mat-error>Campo obrigatório</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>E-mail*</mat-label>
        <input matInput formControlName="email" type="email">
        <mat-error *ngIf="controls.email.hasError('required')">Campo obrigatório</mat-error>
        <mat-error *ngIf="controls.email.hasError('email')">E-mail inválido</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Senha*</mat-label>
        <input matInput formControlName="password" type="password">
        <mat-error *ngIf="controls.password.hasError('required')">Campo obrigatório</mat-error>
        <mat-error *ngIf="controls.password.hasError('minlength')">A sua senha tem que ter pelo menos 6 caracteres</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Confirmação da senha*</mat-label>
        <input matInput formControlName="passwordConfirmation" type="password">
        <mat-error *ngIf="signUpForm.hasError('passwordsDoNotMatch')">As senhas são diferentes</mat-error>
      </mat-form-field>

      <div fxLayout fxLayoutAlign="center center" class="sign-up-button-container">
        <button type="submit" mat-flat-button class="sign-up-button" [ngStyle]="buttonStyle" color="primary">
          <span class="mat-h2">Cadastrar</span>
        </button>
      </div>
    </form>
  </div>

  <!-- Loading Spinner -->
  <div *ngIf="loading" class="loading-spinner">
    <mat-spinner strokeWidth="6"></mat-spinner>
  </div>

  <div *ngIf="error" class="error">
    <app-error-text errorMsg="Ops, ocorreu um erro ao obter os dados da loja."></app-error-text>
  </div>
</div>