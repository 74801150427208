import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService, OS } from 'ngx-device-detector';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  showGooglePlayBagde: boolean;
  showAppleStoreBadge: boolean;

  constructor(private deviceDetector: DeviceDetectorService) { }

  ngOnInit() {
    if (this.deviceDetector.os === OS.ANDROID) {
      this.showGooglePlayBagde = true;
      this.showAppleStoreBadge = false;
    }
    else if (this.deviceDetector.os === OS.IOS) {
      this.showGooglePlayBagde = false;
      this.showAppleStoreBadge = true;
    }
    else {
      this.showGooglePlayBagde = true;
      this.showAppleStoreBadge = true;
    }
  }

}
