<div class="balance mat-typography" fxLayout fxLayoutAlign="center center">

  <div>
    <div class="loyalty-points" fxLayout="column" fxLayoutAlign="center center" [ngStyle]="loyaltyPointsStyle">
      <div class="points-number">
        {{ brand.loyaltyPoints }}
      </div>
      <div class="points-text">
        {{ loyaltyPointsText }}
      </div>
    </div>
  </div>

  <div class="brand-img-wrapper" fxLayout fxLayoutAlign="center center">
    <img [src]="brand.smallLogoUrl" class="brand-img" />
  </div>

  <div class="brand-text mat-small">
    Você tem <strong>{{ brand.loyaltyPoints }} {{ loyaltyPointsText }}</strong> na marca {{ brand.name }} e já pode <strong>resgatá-los através do aplicativo Onyo</strong>.
  </div>

</div>