export const environment = {
  production: false,
  graphqlURL: 'https://graphql.staging.onyo.com/graphql',
  apiURL: 'https://api.staging.onyo.com',
  timeout: 10000,
  retryMinDelay: 250,
  retryMaxDelay: 5000,
  maxRetries: 4,
  sentry: '',
  mixpanel: 'e649bbe1205f7e718f9941bc310b1557',
  trackMixpanel: true // If true, will track mixpanel events (default: only true in production)
};
