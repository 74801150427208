import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Angulartics2Module } from 'angulartics2';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BalanceViewComponent } from './components/balance-view/balance-view.component';
import { BalanceComponent } from './components/balance/balance.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { ErrorTextComponent } from './components/error-text/error-text.component';
import { FooterComponent } from './components/footer/footer.component';
import { MainBalanceComponent } from './components/main-balance/main-balance.component';
import { OnyoLogoComponent } from './components/onyo-logo/onyo-logo.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { WhiteLabelBalanceComponent } from './components/white-label-balance/white-label-balance.component';
import { GraphQLModule } from './graphql.module';
import { MaterialModule } from './material.module';
import { SentryErrorHandler } from './services/sentry/sentry.service';
import { environment } from '@env/environment';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    GraphQLModule,
    FlexLayoutModule,
    MaterialModule,
    DeviceDetectorModule.forRoot(),
    Angulartics2Module.forRoot({
      developerMode: !environment.production,
      pageTracking: {
        clearQueryParams: true,
      }
    }),
  ],
  declarations: [
    AppComponent,
    SignUpComponent,
    FooterComponent,
    ErrorDialogComponent,
    OnyoLogoComponent,
    BalanceComponent,
    MainBalanceComponent,
    BalanceViewComponent,
    PageNotFoundComponent,
    ErrorTextComponent,
    WhiteLabelBalanceComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  entryComponents: [ ErrorDialogComponent ],
  bootstrap: [AppComponent]
})
export class AppModule { }
