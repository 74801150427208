import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/browser';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher  } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { withClientState } from 'apollo-link-state';
import ApolloLinkTimeout from 'apollo-link-timeout';
import result from '@app/graphql/generated-graphql';

export function createApollo(httpLink: HttpLink) {
  const http = httpLink.create({ uri: environment.graphqlURL });

  // ***  Local State ***
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: result
  });
  const localCache = new InMemoryCache({ fragmentMatcher });
  const localState = withClientState({
    cache: localCache,
    defaults: { },
    resolvers: { }
  });

  // *** Timeout Retry ***
  const timeout = new ApolloLinkTimeout(environment.timeout);
  const retry = new RetryLink({
    delay: {
      initial: environment.retryMinDelay,
      max: environment.maxRetries,
      jitter: true
    },
    attempts: {
      max: environment.maxRetries,
      retryIf: (err, op) => err.message.includes('Timeout exceeded') // Only retry timeout errors
    }
  });

  // *** Error handling ***
  const error = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map((graphqlError) => {
        const errorMessage = `[GraphQL error - ${operation.operationName}]: `;
        console.error(errorMessage, graphqlError);
        Sentry.addBreadcrumb({
          message: `[GraphQL error - ${operation.operationName}]`,
          data: {
            errorMsg: graphqlError.message,
            location: JSON.stringify(graphqlError.locations),
            path: graphqlError.path
          },
          level: Sentry.Severity.Error
        });
      });
    }

    if (networkError) {
      const errorMessage = `[Network error - ${operation.operationName}]: `;

      if (networkError.message.includes('Timeout exceeded')) {
        console.warn(errorMessage, networkError);
        Sentry.addBreadcrumb({ message: errorMessage, data: networkError, level: Sentry.Severity.Warning });
      }
      else {
        console.error(errorMessage, networkError);
        Sentry.addBreadcrumb({ message: errorMessage, data: networkError, level: Sentry.Severity.Error });
      }
    }
  });

  return {
    cache: localCache,
    link: ApolloLink.from([localState, retry, error, timeout, http])
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
