import * as jwtDecode from 'jwt-decode';
import * as i0 from "@angular/core";
/**
 * Wrapper for the JWT Decode
 */
export class JWTService {
    constructor() { }
    decodePayload(token) {
        const decodedValue = jwtDecode(token);
        return decodedValue.identity;
    }
}
JWTService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JWTService_Factory() { return new JWTService(); }, token: JWTService, providedIn: "root" });
