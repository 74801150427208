export type Maybe<T> = T | null;

export interface LoginAuthInput {
  email: string;

  password: string;

  userType: AuthUserType;
}

export interface MetabaseResourceUrlInput {
  resourceName: string;

  resourceId: number;

  companies?: Maybe<string>;

  brands?: Maybe<string>;
}

export enum ProductType {
  Simple = "SIMPLE",
  Choosable = "CHOOSABLE",
  MenuItem = "MENU_ITEM"
}

export enum OnlineStatus {
  Online = "ONLINE",
  Offline = "OFFLINE",
  Closed = "CLOSED"
}

export enum OrderFieldType {
  Text = "TEXT",
  Numeric = "NUMERIC",
  Telephone = "TELEPHONE",
  Cpf = "CPF",
  OptionList = "OPTION_LIST"
}

export enum CompanyDemandStatus {
  Unknown = "UNKNOWN",
  Normal = "NORMAL",
  High = "HIGH",
  Blocked = "BLOCKED"
}

export enum AuthUserType {
  Crm = "CRM"
}

/** JSON String */
export type JsonString = any;

/** The `DateTime` scalar type represents a DateTime value as specified by [iso8601](https://en.wikipedia.org/wiki/ISO_8601). */
export type DateTime = any;

// ====================================================
// Documents
// ====================================================

export namespace CustomerBalance {
  export type Variables = {};

  export type Query = {
    __typename?: "Query";

    me: Maybe<Me>;
  };

  export type Me = CustomerInlineFragment | TemporaryUserInlineFragment;

  export type CustomerInlineFragment = {
    __typename?: "Customer";

    balance: Maybe<Balance>;
  };

  export type Balance = {
    __typename?: "BalanceConnection";

    edges: (Maybe<Edges>)[];
  };

  export type Edges = {
    __typename?: "BalanceEdge";

    node: Maybe<Node>;
  };

  export type Node = {
    __typename?: "Balance";

    points: Maybe<number>;

    brand: Maybe<Brand>;
  };

  export type Brand = {
    __typename?: "Brand";

    name: Maybe<string>;

    numericalId: Maybe<number>;

    images: Maybe<(Maybe<Images>)[]>;

    brandTheme: Maybe<BrandTheme>;
  };

  export type Images = {
    __typename?: "Image";

    context: Maybe<string>;

    url: Maybe<string>;
  };

  export type BrandTheme = {
    __typename?: "BrandThemeConnection";

    edges: (Maybe<_Edges>)[];
  };

  export type _Edges = {
    __typename?: "BrandThemeEdge";

    node: Maybe<_Node>;
  };

  export type _Node = {
    __typename?: "BrandTheme";

    brandThemeColor: Maybe<(Maybe<BrandThemeColor>)[]>;
  };

  export type BrandThemeColor = {
    __typename?: "BrandThemeColor";

    key: Maybe<string>;

    value: Maybe<string>;
  };

  export type TemporaryUserInlineFragment = {
    __typename?: "TemporaryUser";

    balance: Maybe<_Balance>;
  };

  export type _Balance = {
    __typename?: "BalanceConnection";

    edges: (Maybe<__Edges>)[];
  };

  export type __Edges = {
    __typename?: "BalanceEdge";

    node: Maybe<__Node>;
  };

  export type __Node = {
    __typename?: "Balance";

    points: Maybe<number>;

    brand: Maybe<_Brand>;
  };

  export type _Brand = {
    __typename?: "Brand";

    name: Maybe<string>;

    numericalId: Maybe<number>;

    images: Maybe<(Maybe<_Images>)[]>;

    brandTheme: Maybe<_BrandTheme>;
  };

  export type _Images = {
    __typename?: "Image";

    context: Maybe<string>;

    url: Maybe<string>;
  };

  export type _BrandTheme = {
    __typename?: "BrandThemeConnection";

    edges: (Maybe<___Edges>)[];
  };

  export type ___Edges = {
    __typename?: "BrandThemeEdge";

    node: Maybe<___Node>;
  };

  export type ___Node = {
    __typename?: "BrandTheme";

    brandThemeColor: Maybe<(Maybe<_BrandThemeColor>)[]>;
  };

  export type _BrandThemeColor = {
    __typename?: "BrandThemeColor";

    key: Maybe<string>;

    value: Maybe<string>;
  };
}

export namespace CustomerBrandBalance {
  export type Variables = {
    brandId: number;
  };

  export type Query = {
    __typename?: "Query";

    me: Maybe<Me>;
  };

  export type Me = CustomerInlineFragment | TemporaryUserInlineFragment;

  export type CustomerInlineFragment = {
    __typename?: "Customer";

    balance: Maybe<Balance>;
  };

  export type Balance = {
    __typename?: "BalanceConnection";

    edges: (Maybe<Edges>)[];
  };

  export type Edges = {
    __typename?: "BalanceEdge";

    node: Maybe<Node>;
  };

  export type Node = {
    __typename?: "Balance";

    points: Maybe<number>;

    brand: Maybe<Brand>;
  };

  export type Brand = {
    __typename?: "Brand";

    name: Maybe<string>;

    numericalId: Maybe<number>;

    images: Maybe<(Maybe<Images>)[]>;

    brandTheme: Maybe<BrandTheme>;
  };

  export type Images = {
    __typename?: "Image";

    context: Maybe<string>;

    url: Maybe<string>;
  };

  export type BrandTheme = {
    __typename?: "BrandThemeConnection";

    edges: (Maybe<_Edges>)[];
  };

  export type _Edges = {
    __typename?: "BrandThemeEdge";

    node: Maybe<_Node>;
  };

  export type _Node = {
    __typename?: "BrandTheme";

    brandThemeColor: Maybe<(Maybe<BrandThemeColor>)[]>;

    brandThemeMessage: Maybe<(Maybe<BrandThemeMessage>)[]>;
  };

  export type BrandThemeColor = {
    __typename?: "BrandThemeColor";

    key: Maybe<string>;

    value: Maybe<string>;
  };

  export type BrandThemeMessage = {
    __typename?: "BrandThemeMessage";

    key: Maybe<string>;

    value: Maybe<string>;
  };

  export type TemporaryUserInlineFragment = {
    __typename?: "TemporaryUser";

    balance: Maybe<_Balance>;
  };

  export type _Balance = {
    __typename?: "BalanceConnection";

    edges: (Maybe<__Edges>)[];
  };

  export type __Edges = {
    __typename?: "BalanceEdge";

    node: Maybe<__Node>;
  };

  export type __Node = {
    __typename?: "Balance";

    points: Maybe<number>;

    brand: Maybe<_Brand>;
  };

  export type _Brand = {
    __typename?: "Brand";

    name: Maybe<string>;

    numericalId: Maybe<number>;

    images: Maybe<(Maybe<_Images>)[]>;

    brandTheme: Maybe<_BrandTheme>;
  };

  export type _Images = {
    __typename?: "Image";

    context: Maybe<string>;

    url: Maybe<string>;
  };

  export type _BrandTheme = {
    __typename?: "BrandThemeConnection";

    edges: (Maybe<___Edges>)[];
  };

  export type ___Edges = {
    __typename?: "BrandThemeEdge";

    node: Maybe<___Node>;
  };

  export type ___Node = {
    __typename?: "BrandTheme";

    brandThemeColor: Maybe<(Maybe<_BrandThemeColor>)[]>;

    brandThemeMessage: Maybe<(Maybe<_BrandThemeMessage>)[]>;
  };

  export type _BrandThemeColor = {
    __typename?: "BrandThemeColor";

    key: Maybe<string>;

    value: Maybe<string>;
  };

  export type _BrandThemeMessage = {
    __typename?: "BrandThemeMessage";

    key: Maybe<string>;

    value: Maybe<string>;
  };
}

export namespace SignUpUser {
  export type Variables = {
    email: string;
    name: string;
    password: string;
    telephone: string;
  };

  export type Mutation = {
    __typename?: "Mutation";

    customerEngagementSignUp: Maybe<CustomerEngagementSignUp>;
  };

  export type CustomerEngagementSignUp = {
    __typename?: "CustomerEngagementSignUp";

    success: Maybe<boolean>;

    error: Maybe<string>;
  };
}

// ====================================================
// START: Apollo Angular template
// ====================================================

import { Injectable } from "@angular/core";
import * as Apollo from "apollo-angular";

import gql from "graphql-tag";

// ====================================================
// Apollo Services
// ====================================================

@Injectable({
  providedIn: "root"
})
export class CustomerBalanceGQL extends Apollo.Query<
  CustomerBalance.Query,
  CustomerBalance.Variables
> {
  document: any = gql`
    query CustomerBalance {
      me {
        ... on Customer {
          balance {
            edges {
              node {
                points
                brand {
                  name
                  numericalId
                  images {
                    context
                    url
                  }
                  brandTheme {
                    edges {
                      node {
                        brandThemeColor {
                          key
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on TemporaryUser {
          balance {
            edges {
              node {
                points
                brand {
                  name
                  numericalId
                  images {
                    context
                    url
                  }
                  brandTheme {
                    edges {
                      node {
                        brandThemeColor {
                          key
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class CustomerBrandBalanceGQL extends Apollo.Query<
  CustomerBrandBalance.Query,
  CustomerBrandBalance.Variables
> {
  document: any = gql`
    query CustomerBrandBalance($brandId: Int!) {
      me {
        ... on Customer {
          balance(brandId: $brandId) {
            edges {
              node {
                points
                brand {
                  name
                  numericalId
                  images {
                    context
                    url
                  }
                  brandTheme {
                    edges {
                      node {
                        brandThemeColor {
                          key
                          value
                        }
                        brandThemeMessage {
                          key
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on TemporaryUser {
          balance(brandId: $brandId) {
            edges {
              node {
                points
                brand {
                  name
                  numericalId
                  images {
                    context
                    url
                  }
                  brandTheme {
                    edges {
                      node {
                        brandThemeColor {
                          key
                          value
                        }
                        brandThemeMessage {
                          key
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
@Injectable({
  providedIn: "root"
})
export class SignUpUserGQL extends Apollo.Mutation<
  SignUpUser.Mutation,
  SignUpUser.Variables
> {
  document: any = gql`
    mutation SignUpUser(
      $email: String!
      $name: String!
      $password: String!
      $telephone: String!
    ) {
      customerEngagementSignUp(
        email: $email
        name: $name
        password: $password
        telephone: $telephone
      ) {
        success
        error
      }
    }
  `;
}

// ====================================================
// END: Apollo Angular template
// ====================================================

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}

const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: "INTERFACE",
        name: "Node",
        possibleTypes: [
          {
            name: "Company"
          },
          {
            name: "Brand"
          },
          {
            name: "Category"
          },
          {
            name: "Product"
          },
          {
            name: "ProductCompany"
          },
          {
            name: "BrandTheme"
          },
          {
            name: "BrandThemeColor"
          },
          {
            name: "BrandThemeMessage"
          },
          {
            name: "Address"
          },
          {
            name: "Parameter"
          },
          {
            name: "PaymentParameter"
          },
          {
            name: "CardBrand"
          },
          {
            name: "OrderTypeParameter"
          },
          {
            name: "OrderFieldParameter"
          },
          {
            name: "OrderField"
          },
          {
            name: "CompanyGroup"
          },
          {
            name: "AccountsUser"
          },
          {
            name: "User"
          },
          {
            name: "Customer"
          },
          {
            name: "Reward"
          },
          {
            name: "TemporaryUser"
          }
        ]
      },
      {
        kind: "UNION",
        name: "Person",
        possibleTypes: [
          {
            name: "Customer"
          },
          {
            name: "TemporaryUser"
          }
        ]
      }
    ]
  }
};

export default result;
