/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./balance-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../main-balance/main-balance.component.ngfactory";
import * as i3 from "../main-balance/main-balance.component";
import * as i4 from "../white-label-balance/white-label-balance.component.ngfactory";
import * as i5 from "../white-label-balance/white-label-balance.component";
import * as i6 from "../balance/balance.component.ngfactory";
import * as i7 from "../balance/balance.component";
import * as i8 from "@angular/common";
import * as i9 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i10 from "@angular/material/progress-spinner";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "../error-text/error-text.component.ngfactory";
import * as i14 from "../error-text/error-text.component";
import * as i15 from "@angular/flex-layout/flex";
import * as i16 from "@angular/flex-layout/core";
import * as i17 from "./balance-view.component";
import * as i18 from "../../graphql/generated-graphql";
import * as i19 from "@angular/router";
import * as i20 from "../../services/jwt/jwt.service";
import * as i21 from "../../services/brand-parser/brand-parser.service";
import * as i22 from "angulartics2";
var styles_BalanceViewComponent = [i0.styles];
var RenderType_BalanceViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BalanceViewComponent, data: {} });
export { RenderType_BalanceViewComponent as RenderType_BalanceViewComponent };
function View_BalanceViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "onyo-app"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-main-balance", [], null, null, null, i2.View_MainBalanceComponent_0, i2.RenderType_MainBalanceComponent)), i1.ɵdid(2, 114688, null, 0, i3.MainBalanceComponent, [], { brand: [0, "brand"], hasAdditionalPoints: [1, "hasAdditionalPoints"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mainBrand; var currVal_1 = _co.hasAdditionalPoints; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_BalanceViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "white-label-app"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-white-label-balance", [], null, null, null, i4.View_WhiteLabelBalanceComponent_0, i4.RenderType_WhiteLabelBalanceComponent)), i1.ɵdid(2, 114688, null, 0, i5.WhiteLabelBalanceComponent, [], { brand: [0, "brand"], hasAdditionalPoints: [1, "hasAdditionalPoints"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mainBrand; var currVal_1 = _co.hasAdditionalPoints; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_BalanceViewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-balance", [], null, null, null, i6.View_BalanceComponent_0, i6.RenderType_BalanceComponent)), i1.ɵdid(2, 114688, null, 0, i7.BalanceComponent, [], { brand: [0, "brand"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_BalanceViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "balance"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceViewComponent_2)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceViewComponent_3)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceViewComponent_4)), i1.ɵdid(6, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isWhiteLabel; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isWhiteLabel; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.brands; _ck(_v, 6, 0, currVal_2); }, null); }
function View_BalanceViewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"], ["strokeWidth", "6"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i9.View_MatSpinner_0, i9.RenderType_MatSpinner)), i1.ɵdid(2, 49152, null, 0, i10.MatSpinner, [i1.ElementRef, i11.Platform, [2, i8.DOCUMENT], [2, i12.ANIMATION_MODULE_TYPE], i10.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { strokeWidth: [0, "strokeWidth"] }, null)], function (_ck, _v) { var currVal_3 = "6"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_BalanceViewComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-error-text", [["errorMsg", "Ops, ocorreu um erro ao carregar os seus dados."]], null, null, null, i13.View_ErrorTextComponent_0, i13.RenderType_ErrorTextComponent)), i1.ɵdid(2, 114688, null, 0, i14.ErrorTextComponent, [], { errorMsg: [0, "errorMsg"] }, null)], function (_ck, _v) { var currVal_0 = "Ops, ocorreu um erro ao carregar os seus dados."; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BalanceViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "balance-view"], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i15.DefaultLayoutDirective, [i1.ElementRef, i16.StyleUtils, [2, i15.LayoutStyleBuilder], i16.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i15.DefaultLayoutAlignDirective, [i1.ElementRef, i16.StyleUtils, [2, i15.LayoutAlignStyleBuilder], i16.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceViewComponent_1)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceViewComponent_5)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BalanceViewComponent_6)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = (!_co.loading && !_co.showError); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.loading; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.showError; _ck(_v, 8, 0, currVal_4); }, null); }
export function View_BalanceViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-balance-view", [], null, null, null, View_BalanceViewComponent_0, RenderType_BalanceViewComponent)), i1.ɵdid(1, 114688, null, 0, i17.BalanceViewComponent, [i18.CustomerBalanceGQL, i19.ActivatedRoute, i20.JWTService, i21.BrandParserService, i22.Angulartics2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BalanceViewComponentNgFactory = i1.ɵccf("app-balance-view", i17.BalanceViewComponent, View_BalanceViewComponent_Host_0, {}, {}, []);
export { BalanceViewComponentNgFactory as BalanceViewComponentNgFactory };
